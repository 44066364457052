import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import { Button as MuiButton, Typography} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  text-align: center;
  background-color: ${props => props.theme.palette.primary.main};
  height: 100%;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return(
        <AuthLayout>
          <Wrapper>
            <Typography component="h2" variant="h1" align="center" gutterBottom style={{color:'#FFFFFF'}}>
              Ocurrió un error inesperado.
            </Typography>
            <Button 
              component={Link}
              to="/" 
              style={{padding: '20px', width: '100%', textAlign: 'center', color:"#FFFFFF"}}>
            <Typography component="h2" variant="h4" align="center" gutterBottom>
              Haga click aquí para recargar la pagina. <br/>
              Si el error persiste por favor contactenos.
            </Typography> 
            </Button>
          </Wrapper>
        </AuthLayout>
    );
  }
    return this.props.children;
  }
} 
import * as types from '../constants';

export function loadItems(model, params, itemsListId='') {
  return {
    type: types.LOAD_ITEMS,
    payload: {
      model,
      params,
      itemsListId
    }
  }
}

export function loadItemsDone(model, values, itemsListId='') {
  return {
    type: types.LOAD_ITEMS_DONE,
    payload: {
      model,
      values,
      itemsListId
    }
  }
}

export function loadItemsFailed(model, error, itemsListId='') {
  return {
    type: types.LOAD_ITEMS_FAILED,
    payload: {
      model,
      error,
      itemsListId
    }
  }
}

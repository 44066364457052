export const SET_THEME = 'SET_THEME';
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_SIGNED_OUT = 'SET_SIGNED_OUT';
export const SET_RESET_PASSWORD_DONE = 'SET_RESET_PASSWORD_DONE';
export const SET_NEW_PASSWORD_DONE = 'SET_NEW_PASSWORD_DONE';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_SUBSCRIBED = 'SET_SUBSCRIBED';
export const SET_UNSUBSCRIBED = 'SET_UNSUBSCRIBED';

export const LOAD_ITEMS = 'LOAD_ITEMS';
export const LOAD_ITEMS_DONE = 'LOAD_ITEMS_DONE';
export const LOAD_ITEMS_FAILED = 'LOAD_ITEMS_FAILED';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';


export const SET_FORM_STATUS = 'SET_FORM_STATUS';

export const SET_ENABLED_INTROJS = 'SET_ENABLED_INTROJS';

export const SET_STEP_UPDATE = 'SET_STEP_UPDATE';
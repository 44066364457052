import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {auth as authRoutes, originalMenu, publicRoutes, SignIn} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PublicLayout from "../layouts/Public";
import Page404 from "../pages/auth/Page404";

import ErrorBoundary from "../components/ErrorBoundary";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component, name}, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} name={name}/>
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );


export const Routes = () => {
  return <ErrorBoundary>
    <Switch>
      {childRoutes(AuthLayout, authRoutes)}
      {childRoutes(DashboardLayout, originalMenu)}
      {childRoutes(PublicLayout, publicRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </ErrorBoundary>
};

export default Routes;

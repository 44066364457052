import * as types from '../constants';

export default function reducer(state={}, {type, payload = {}}) {
  const { model, dirty } = payload;

  switch (type) {

    case types.SET_FORM_STATUS:
      return {
        ...state,
        [model]: {
          dirty
        }
      };

    default:
      return state
  }
}

export const colors = {
  principal: {
    blue: '#003153',
    pink: '#ED6280'
  },
  secondary: {
    blue: '#496A81',
    green: '#66999B',
    grey: '#9EA3B0',
    lightGrey: '#ECEEF1',
  },
}
import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { Grid } from "@material-ui/core";
import { colors } from "../theme/colors";
import logo from '../images/logoWhiteGradientx2.png';
import { Logo } from "../components/Logo"
import LayoutScripts from "./layoutScripts";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  padding-bottom: 5%;
`;

function Auth({ children }) {
  return (
    <Root>
      <LayoutScripts/>
      <GlobalStyle />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item>
          <Logo logo={logo} padding={'30px'} width={'250px'} />
        </Grid>
        <Grid item xs={ 12 }>
          {children}
        </Grid>
    </Grid>
    </Root>
  );
}

export default Auth;

import mixpanel from 'mixpanel-browser';

const mixpanelToken = '46be4d299db10ac82b42ac6d28eca59b'

const TrackerService = {
    logIn: function(user_id, params = {}) {
        if(process.env.NODE_ENV === 'production') {
            mixpanel.init(mixpanelToken);
            mixpanel.identify(user_id)
            mixpanel.people.set(params);
            mixpanel.track('Inicio sesion');
        }
    },

    track: function(event, params = {}) {
        if(process.env.NODE_ENV === 'production'){
        mixpanel.init(mixpanelToken);
        mixpanel.track(event, params);
        }
    }
}

export default TrackerService
import React from "react";
import { Tabs, Tab } from "@material-ui/core";
import Check from '@material-ui/icons/Check';
import { Avatar } from "@material-ui/core";
import styled from "styled-components";
import { LocalStorage } from "../utils/storage";
import { connect } from "react-redux";
import store from "../redux/store";
import { setFirstSteps } from "../redux/actions/firstStepsActions";
import {
  Dialog, DialogContent, DialogContentText,
  Button, DialogActions
} from "@material-ui/core";
import { Logo } from './Logo'
import logo from '../images/Logo-Trazability.png';
import { makeStyles } from '@material-ui/core/styles';
import { FirstStepsService } from "../services/FirstStepsService";

const useStyles = makeStyles(theme => ({
  customMaxWidth: {
      maxWidth: "670px"
    }
}));

const drawerWidth = '20vw';

const StyledTabs = styled(Tabs)`
  flex: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  
  ${props => props.theme.breakpoints.down("md")} {
    max-width: 100vw;
  }
  
  ${props => props.theme.breakpoints.up("md")} {
    max-width: calc(100vw - ${drawerWidth});
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }
`;

 const StyledTab = styled(Tab)`
 
  max-width: none !important;
  max-height: 56px;
  width: ${window.innerWidth > 1700? 'auto': 'min-content'};

 
 `;

const tabLabel = (label, index, done) => (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingRight:'30px'}}>
        <Avatar 
        style={{ width: '30px', height: '30px', marginRight:'20px', color:'#626262', border:'2px solid #dadada', backgroundColor:'#fafafa'}} size='20'>
            {done? <Check color='secondary'/> : index}
        </Avatar>
        <div style={{textAlign:'left', fontSize:'16px', minWidth:'200 px'}}>{label}</div>
    </div>
    )

export const steps = {
    PRODUCTION_CONFIGURATIONS: 'ProductionConfigurations',
    PRODUCTIONS: 'Productions',
    PRODUCT_CONFIGURATIONS: 'Products',
    RAW_MATERIAL_CONFIGURATIONS: 'RawMaterials',
    RAW_MATERIAL_RECEPTIONS: 'Receptions',
    SEARCH: 'Search',
    SUPPLIER_CONFIGURATIONS: 'Suppliers',
}

const firstSteps = [
    [
        {
            name: steps['SUPPLIER_CONFIGURATIONS'],
            label: 'Configurá tus Proveedores',
            url: '/suppliers',
            index: '1',
            minWidth: '205px'
        },
        {
            name: steps['RAW_MATERIAL_CONFIGURATIONS'],
            label: 'Configurá tus Materias Primas',
            url: '/raw_materials',
            index: '2',
            minWidth: '245px'
        },
        {
          name: steps['PRODUCTION_CONFIGURATIONS'],
          label: 'Configurá tu Producción paso a paso',
          url: '/production_configuration',
          index: '3',
          minWidth: '290px'
        },
        {
            name: steps['PRODUCT_CONFIGURATIONS'],
            label: 'Configurá tu Producto Final',
            url: '/products',
            index: '4',
            minWidth: '220px'
        },
    ],
    [
        {
            name: steps['RAW_MATERIAL_RECEPTIONS'],
            label: 'Registrá el ingreso de tus Materias Primas',
            url: '/register/reception',
            index: '5',
            minWidth: '270px'
        },
        {
            name: steps['PRODUCTIONS'],
            label: 'Cargá tu Producción',
            url: '/production',
            index: '6',
            minWidth: '170px'
        },
        {
            name: steps['SEARCH'],
            label: 'Trazabilidad',
            url: '/traceability',
            index: '7',
            minWidth: '170px'
        }
    ]
]

function FirstSteps({updateFirstSteps}) {
  const isAdmin = LocalStorage.get('isAdmin')
    return (
      !firstStepsDone() && isAdmin && updateFirstSteps != null?
        <StyledTabs style={{height:'60px', minHeight:'60px', maxHeight:'60px'}} variant='scrollable' >
            {firstSteps.find((e) => e.some((step) => !isDone(step.name))).map((step) => 
            <StyledTab 
                style={{minWidth: step.minWidth}} 
                label={tabLabel(step.label, step.index, isDone(step.name))} 
                href={step.url} />)}
        </StyledTabs>
        :
        <FirstStepsDialog 
        open={!isDone('Done')}
        handleClose={()=> {
          setStepDone('Done')
          FirstStepsService.updateFirstStepsFlags('Done')
        }}
        text={<>
        Ya terminaste los 7 pasos principales para implementar TRAZAL <br/>
        Podés seguir navegando y cargando más datos desde el menú
        </>
        }
      />
    );
  }

  export const isDone = (step_name, firstStepsFlags = LocalStorage.get('firstStepsFlags')) =>{
    return firstStepsFlags && firstStepsFlags.includes(step_name)
  }

  export const setStepDone = (step_name) => {
    if(isDone(step_name)) return 
    const firstStepsFlags = LocalStorage.get('firstStepsFlags')
    LocalStorage.set('firstStepsFlags', [...firstStepsFlags, step_name])
    store.dispatch(setFirstSteps(true))
  }

  const firstStepsDone = (firstStepsFlags) =>{
    return firstSteps.flat(1).every((e) => isDone(e.name, firstStepsFlags))
  }

  const mapStateToProps = state => ({
    stepsDone: state.firstSteps.done,
    updateFirstSteps: state.firstSteps.updated
})

export function FirstStepsDialog({open, handleClose, text}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      classes={{ paperScrollPaper: classes.customMaxWidth }}
    >
    <DialogContent style={{textAlign:'center', width:'100%', paddingTop:'30px'}}>
    <Logo logo={logo} width={'200px'}/>
      <DialogContentText id="alert-dialog-description" style={{padding:'10px', fontSize:'17px'}}>
      {text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" variant="contained">
        Aceptar
      </Button>
    </DialogActions>
    </Dialog>
  );
}

export default connect(mapStateToProps)(FirstSteps)
import * as types from "../constants";

export default function reducer(state={value:false}, {type, payload = {}}) {
  switch (type) {

    case types.SET_ENABLED_INTROJS:
      return {
        ...state,
        value: payload
      }

    default:
      return state
  }
}

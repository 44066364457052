import React from "react";

const injectGA = () => {
  if (typeof window == 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'G-9XN9M5VC0L');
};

function LayoutScripts() {
  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-9XN9M5VC0L"
      />
      <script>{injectGA()}</script>
    </>
  );
}

export default LayoutScripts;

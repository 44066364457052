import API from './api'
import { Urls } from './urls'
import {LocalStorage} from "../utils/storage";

export const IntroJsService = {
    updateIntroJsFlags: function() {
        if(! localStorage.getItem('loginToken')) return 
        const data = { user:{ intro_js_flags: LocalStorage.get('introJsFlags') } }
        return API.put(Urls.ApiUpdateIntroJsFlags, data)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                throw error
            })
    },
}


import store from "../redux/store";
import {signInPath} from "../routes/paths";
import {setSignedOut} from "../redux/actions/authActions";
import {Urls} from "./urls";


export const handle401 = error => {
  const originalRequest = error.config;
  if(originalRequest.url !== Urls.ApiLogIn){
    store.dispatch(setSignedOut());
    window.location = signInPath;
  }
};
import API from './api'
import { Urls } from './urls'
import {LocalStorage} from "../utils/storage";

export const FirstStepsService = {
    updateFirstStepsFlags: function(newFlag) {
        const data = { first_steps_flag:  newFlag} 
        return API.put(Urls.ApiUpdateFirstStepsFlags, data)
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                throw error
            })
    },
}


import API from './api';
import { Urls } from './urls';

export const NotificationsService = {
    getNotifications: function (params) {
        return API.get(Urls.ApiNotification, { params: params });
    },

    getNotificationsCount: function () {
        return API.get(Urls.ApiNotification + "unread_count")
    },
}
import React, {useEffect} from "react";
import store from '../redux/store'
import {
    Grid,
    AppBar as MuiAppBar,
    Toolbar, Typography,
} from "@material-ui/core";
import {enqueueSnackbar} from "../redux/actions/snackbarActions";
import {errorMessage} from "../services/alertMessages";
import snackbarMessages from "../services/snackbarMessages";
import {LocalStorage} from "../utils/storage";
import {NotificationMessageService} from "../services/NotificationMessageService";


export default function NotificationBanner({open,handleClose, receptionId, date}){
  const [bannerMessage, setBannerMessage] = React.useState('')

  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  const expireTime = 100000;

  let notification = LocalStorage.get('notificationMessage', {'message': '', 'time': timestamp})

  useEffect(
    () => {
      //Si no hay una notificación guardada con un tiempo de expiración vigente, se hace el get y se setea un nuevo tiempo de exp.
      if(notification.time<=timestamp && localStorage.getItem('loginToken')) {
          NotificationMessageService.get()
              .then((response) => {
                const message = response.data.message;
                if (message){
                  LocalStorage.set('notificationMessage', {'message': message, 'time': timestamp+expireTime })
                  setBannerMessage(message)
                }
              })
              .catch(() =>
                  store.dispatch(enqueueSnackbar(errorMessage(snackbarMessages.error.notification_message.get)))
                  && handleClose())

      }
      else{
          setBannerMessage(notification.message)
      }
    },
    [notification]
  )

  //En el caso de que haya un mensaje de notidicación se muestra el banner
  return (
          <React.Fragment>
              {(bannerMessage === '') ?
                    <div></div>
                  :
                          <Grid container style={{
                              backgroundColor: 'rgba(232,207,0,0.49)',
                              justifyContent: "center"
                          }}>
                              <Grid item={12} alignContent={"center"}>
                                  <Typography variant="subtitle2" gutterBottom>
                                      {bannerMessage}
                                  </Typography>
                              </Grid>
                          </Grid>

              }
    </React.Fragment>


  )
}

import async from "../components/Async";
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import Loader from "../components/Loader";
import { hasAnyPermission, hasPermission, Permissions, permissionTypes } from "../components/permissions/permissions";

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: state => state.auth.signedIn,
  authenticatingSelector: state => state.auth.loading,
  wrapperDisplayName: 'UserIsAuthenticated'
}

export const userIsAuthenticated = connectedAuthWrapper(userIsAuthenticatedDefaults)

const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loader,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/auth/sign-in',
})

export const NeedsLogin = component => userIsAuthenticatedRedir(
  async(component)
)

export const NeedsSubscription = (component) => userIsSubscribedRedir(
  async(component)
)

const userIsSubscribedRedir = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.subscribed && state.auth.signedIn,
  wrapperDisplayName: 'UserIsSubscribed'
})

export const NeedsPermission = (component, permission, permission_type) => userHasPermission(
  async(component), permission, permission_type
)

const userHasPermission = (Component, permission, permission_type) => connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => state.auth.subscribed && state.auth.signedIn && hasPermission(permission, permission_type),
  wrapperDisplayName: 'userHasPermission'
})(Component)

export const NeedsProductionPermissions = (component) => userHasProductionPermissions(
  async(component)
)

const userHasProductionPermissions = (Component) => connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: (state, ownProps) => {
    const isSubscribed = state.auth.subscribed && state.auth.signedIn
    switch (ownProps.match.path){
      case "/finished_productions":
        return isSubscribed && (hasAnyPermission([Permissions['FINISHED_PRODUCTIONS'], Permissions['PRODUCTIONS']]) 
                                || hasPermission(Permissions['PRODUCTIONS'], permissionTypes['WRITE']));
      case "/started_productions":
        return isSubscribed && hasAnyPermission([Permissions['STARTED_PRODUCTIONS'], Permissions['PRODUCTIONS']]);
      default:
        return isSubscribed && (hasPermission(Permissions['PRODUCTIONS'])
                          || hasPermission(Permissions['STARTED_PRODUCTIONS'], permissionTypes['WRITE']))
    }},
  wrapperDisplayName: 'userHasProductionPermissions'
})(Component)

export const NeedsAdminPermission = (component) => userIsAdminRedir(
  async(component)
)

const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.isAdmin,
  wrapperDisplayName: 'UserIsAdmin'
})

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: state => !state.auth.signedIn && !state.auth.loading,
  authenticatingSelector: state => state.auth.loading,
  wrapperDisplayName: 'UserIsNotAuthenticated'
}

export const userIsNotAuthenticated = connectedAuthWrapper(userIsNotAuthenticatedDefaults)

const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false
})

export const NoLogin = component => userIsNotAuthenticatedRedir(
  async(component)
)
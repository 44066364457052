import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline, Grid } from "@material-ui/core";
import LayoutScripts from "./layoutScripts";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function PublicLayout({ children }) {
  return (
    <Root>
    <LayoutScripts/>
        <CssBaseline />
      <GlobalStyle />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={ 12 }>
          {children}
        </Grid>
    </Grid>
    </Root>
  );
}

export default PublicLayout;

import * as types from '../constants';
import { getKey } from '../helpers'

export default function reducer(state={}, {type, payload = {}}) {
  const { model, values, error } = payload;
  const key = getKey(payload.itemsListId, model)

  switch (type) {

    case types.LOAD_ITEMS:
      return {
        ...state,
        [key]: {
          loading: true,
          values: []
        }
      };

    case types.LOAD_ITEMS_DONE:
      return {
        ...state,
        [key]: {
          loading: false,
          ...values
        }
      };

    case types.LOAD_ITEMS_FAILED:
      return {
        ...state,
        [key]: {
          loading: false,
          values: [],
          error
        }
      };

    default:
      return state
  }
}

import React from 'react'
import {
  Breadcrumbs as MuiBreadcrumbs,
} from "@material-ui/core";
import { NavLink as RouterNavLink } from "react-router-dom";
import { originalMenu } from "../routes/index";
import styled from "styled-components";

const ActualPath = styled.div`
  color: ${props => props.theme.sidebar.icon.color};
  font-weight: bold;
`;

const OtherPath = styled.div`
  color: ${props => props.theme.header.breadcrumb.color}
  font-weight: bold;
`;

const Breadcrumbs = styled(MuiBreadcrumbs)`
  color: ${props => props.theme.header.breadcrumb.color} !important;
  font-weight: bold;
`;

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props}/>
));

const findRoute = (history) => {
  let route = originalMenu.find((element) => element.path === history.location.pathname)
  if (route === undefined){
    route = originalMenu.find((element) => {
      return element.children.some((child) => {
        return child.path === history.location.pathname;
      });
    });
  }
  return route
}

const findChild = (route, history) => {
  return route?.children?.find((child) => child.path === history.location.pathname)
}

export default function Breadcrumb ({history})  {
  let route = findRoute(history)

  let labels = { 
    section: route?.header,
    menu: route?.id || '', 
    submenu: findChild(route, history)?.name || ''
  }

  return (
    <Breadcrumbs aria-label="Breadcrumb" mt={2}>
      {labels.section && <OtherPath>{labels.section}</OtherPath>}
      {
        labels.submenu ? 
          <OtherPath>{labels.menu}</OtherPath> 
          : 
          <ActualPath>{labels.menu}</ActualPath> 
      }
      {labels.submenu && <ActualPath>{labels.submenu}</ActualPath>}
    </Breadcrumbs>
  )
}

import * as types from "../constants";

export default function reducer(state = {updated:false}, {type, payload = {updated: true}}) {
  switch (type) {

    case types.SET_STEP_UPDATE:
        return {
            ...state,
            updated: payload
          }

    default:
        return state
  }
}

import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  Avatar,
  Box as MuiBox,
  Collapse,
  Grid,
  Chip,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  List as MuiList,
  Typography
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { originalMenu } from "../routes/index";

import { Layers } from "react-feather";

import logo from '../images/logoWhiteGradient.png';

import { Logo } from '../components/Logo'
import {Steps} from "intro.js-react";


import messages from '../introjs/messages'
import introjs from '../introjs/introjs.css'
import {LocalStorage} from "../utils/storage";
import {IntroJsService} from "../services/IntroJsService";
import { isDone } from "./FirstSteps";
import { hideConfigurationRoutes, hideRegisterRoutes, hideAnalysisRoutes } from '../routes/index'


let routes = originalMenu.filter(child => !child.hidden);

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;
  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const BrandIcon = styled(Layers)`
  margin-right: ${props => props.theme.spacing(2)}px;
  color: ${props => props.theme.sidebar.header.brand.color};
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  svg {
    color: ${props => props.theme.sidebar.icon.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.05, props.theme.sidebar.background)};
    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightBold};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.icon.color, 1)};
  fontWeight: bold;
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.icon.color, 1)};
  fontWeight: bold;
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(14)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;
  span {
    color: ${props => rgba(props.theme.sidebar.color, 1)};
    font-weight: bold;
  }
  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }
  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};
    span {
      color: ${props => props.theme.sidebar.color};
      font-weight: bold;
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  font-weight: bold;
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};
  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(4)}px
    ${props => props.theme.spacing(4)}px;
  display: block;
  font-size: ${props => props.theme.typography.categoryText.fontSize}px !important;
  font-weight: bold !important;
  border-top: 1px solid ${props => props.borderTop || props.theme.sidebar.header.color};
  border-bottom: 1px solid ${props => props.theme.sidebar.header.color};
`;

const SidebarFooter = styled.div`
  background-color: ${props =>
    props.theme.sidebar.footer.background} !important;
  padding: ${props => props.theme.spacing(3)}px
    ${props => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${props => props.theme.sidebar.footer.color};
`;

const Dot = styled.span`
  width: 12px;
  height: 12px;
  margin-right: 4px;
  background-color: ${props => props.theme.sidebar.footer.online.background};
  display: inline-block;
  border-radius: 50%;
  margin-bottom: -0.5px;
`;


function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, badge }) {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      style={{paddingLeft: "52px"}}
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepsEnabled:!(LocalStorage.existsIn('introJsFlags', 'sidebar')),
      initialStep: 0,
      steps: [
        !hideConfigurationRoutes && {
          position:"right",
          element: ".Configuración",
          intro: messages.sidebar.configuration
        },
        !hideRegisterRoutes && {
          position:"right",
          element: ".Registros",
          intro: messages.sidebar.register
        },
        !hideAnalysisRoutes && {
          position:"right",
          element: ".Trazabilidad",
          intro: messages.sidebar.trazability
        },

      ].filter(Boolean)
    };
  }

  toggle = index => {
    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;
    routes.forEach((route, index) => {
      const isActive =
        route.children ? route.children.map((child) => child.path).includes(pathName) : false
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  render() {
    const { classes, staticContext, ...other } = this.props;
    const {
      stepsEnabled,
      steps,
      initialStep,
    } = this.state;

    if(stepsEnabled){
        LocalStorage.addIfNotExist('introJsFlags', 'dataTable')
    }

    return (
     <div>
      <Steps
        options={{
          showStepNumbers: false,
          overlayOpacity: 0,
          scrollToElement: true,
          nextLabel: "Siguiente",
          prevLabel: "Atras",
          skipLabel: "x",
          doneLabel: "Terminar"
        }}
        enabled={stepsEnabled && isDone('Intro')}
        steps={steps}
        initialStep={initialStep}
        onExit={this.onExit}
      />
      <Drawer variant="permanent" {...other}>
        <Brand>
        <a  href={'/'}>
        <Logo logo={logo} padding={'0'} width={'100px'}/>
        </a>
        </Brand>
        <Scrollbar>
          <List disablePadding>
            <Items>
              {routes.map((category, index) => (
                <React.Fragment key={index}>
                  {category.displayHeader?
                  category.header? (
                    <SidebarSection borderTop={index === 0 && 'none'} className={`${category.header}`}>{category.header}</SidebarSection>
                  ): <SidebarSection style={{padding:'0px', borderBottom:'none'}} className={`${category.header}`}/>
                   : null}

                  {category.children ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!this.state[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        button={true}
                        onClick={() => this.toggle(index)}
                      />

                      <Collapse
                        in={this.state[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.filter(child => !child.hidden).map((route, index) => (
                          <SidebarLink
                            key={index}
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            badge={route.badge}
                          />
                        ))}
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.id}
                      to={category.externalURL?{pathname: category.path}: category.path}
                      target={category.externalURL?'_blank':'_self'}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      exact
                      badge={category.badge}
                    />
                  )}
                </React.Fragment>
              ))}
            </Items>
          </List>
        </Scrollbar>
      </Drawer>
     </div>
    );
  }
 onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
    if (!LocalStorage.existsIn('introJsFlags', 'sidebar')) {
        LocalStorage.addIfNotExist('introJsFlags', 'sidebar')
        LocalStorage.removeFromList('introJsFlags', 'dataTable')
        LocalStorage.set('introDataTable', true)
        IntroJsService.updateIntroJsFlags()
    }
  }

}

export default withRouter(Sidebar);

import { blue, grey } from "@material-ui/core/colors";
import {colors} from "./colors"

const blueVariant = {
  name: "Blue",
  palette: {
    primary: {
      main: colors.principal.blue,
      contrastText: "#FFF"
    },
    secondary: {
      main: colors.principal.pink,
      contrastText: "#FFF"
    }
  },
  header: {
    color: colors.principal.blue,
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: colors.principal.blue
    },
    breadcrumb: {
      color: "#FFF"
    },
  },
  sidebar: {
    color: colors.principal.blue,
    fontWeight: "bold",
    background: colors.secondary.lightGrey,
    icon: {
      color: colors.principal.pink
    },
    header: {
      color: colors.principal.pink,
      background: colors.principal.blue,
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: blue[800],
      online: {
        background: "#FFF"
      }
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: colors.principal.blue
  }
};


const variants = [
  blueVariant,
];

export default variants;

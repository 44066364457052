import { take, put, fork, call } from 'redux-saga/effects';
import * as types from '../constants';
import {ItemsService} from "../../services/ItemsService";
import {loadItemsDone, loadItemsFailed} from "../actions/itemActions";

export function* loadItems(model, params={}, itemsListId) {
  const {data, error} = yield call(ItemsService.get, model, params);

  if(data){
    yield put(loadItemsDone(model, data, itemsListId ));
  } else {
    yield put(loadItemsFailed(model, error,itemsListId));
  }
}

export function* loadItemsHandler() {
  while (true) {
    const { payload } = yield take(types.LOAD_ITEMS);
    const { model, params, itemsListId } = payload;
    yield fork(loadItems, model, params, itemsListId);
  }
}

export default [
  loadItemsHandler
];

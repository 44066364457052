import API from './api'
import { Urls } from './urls'
import store from '../redux/store'
import { enqueueSnackbar } from '../redux/actions/snackbarActions'
import { redirectTo } from '../routes/functions'
import { setSignedIn, setSignedOut, setResetPasswordDone, setNewPasswordDone, setAuthLoading, 
    setAuthError, setSubscribed, setUnsubscribed } from '../redux/actions/authActions'
import { successMessage, warningMessage, errorMessage } from './alertMessages'
import snackbarMessages from "./snackbarMessages"
import {LocalStorage} from "../utils/storage";
import jwt_decode from "jwt-decode";
import TrackerService from './TrackerService'

export const AuthService = {
    logIn: function (email, password, history) {
        store.dispatch(setAuthLoading())

        const data = {user: { email: email, password: password}}
        return API.post(Urls.ApiLogIn, data)
            .then(function (response) {
                const token = response.headers.authorization;
                const email = response.data.email;
                const introJsFlags = response.data.intro_js_flags;
                const featureFlags = response.data.feature_flags || [];
                const firstStepsFlags = response.data.first_steps_flags || [];
                const expiration_date = response.data.expiration_date + "T00:00:00";
                const trial_period = response.data.trial_period;
                const subscribed = response.data.subscribed;
                const user_id = response.data.id;
                const plan_name = response.data.subscription_plan_formatted_name;
                const customForms = response.data.custom_forms;

                localStorage.setItem('loginToken', token)
                localStorage.setItem('loginEmail', email)
                localStorage.setItem('awsBucketName', response.data.aws_bucket_name)
                localStorage.setItem('awsSecretAccessKey', response.data.aws_secret_access_key)
                localStorage.setItem('awsAccessKeyId', response.data.aws_access_key_id)
                LocalStorage.set('featureFlags', featureFlags)
                LocalStorage.set('introJsFlags', introJsFlags)
                LocalStorage.set('customForms', customForms)
                LocalStorage.set('firstStepsFlags', firstStepsFlags)
                LocalStorage.set('subscribed', subscribed)
                LocalStorage.set('trial_period', trial_period)
                localStorage.setItem('expiration_date', expiration_date)
                localStorage.setItem('signUpDate', response.data.sign_up_date)
                LocalStorage.set('permissions', response.data.permissions)
                LocalStorage.set('plan_features', response.data.plan_features)
                LocalStorage.set('exceeds_concurrent_users', response.data.exceeds_concurrent_users) 
                LocalStorage.set('subscription_plan_name', plan_name)
                LocalStorage.set('isAdmin', response.data.admin)
                localStorage.setItem('date_format', response.data.date_format)
                localStorage.setItem('rows_per_page', response.data.default_rows_per_page)
                localStorage.setItem('user_email', response.data.email)
                localStorage.setItem('user_first_name', response.data.first_name)
                localStorage.setItem('user_last_name', response.data.last_name)

                const decoded = jwt_decode(token);
                LocalStorage.set('loginTokenExp', decoded.exp * 1000);
                TrackerService.logIn(user_id, {
                    'First Name': response.data.first_name,
                    'Last Name': response.data.last_name,
                    'Email': email,
                    'Plan': subscribed? plan_name : 'No Suscripto'
                })

                API.defaults.headers.common['Authorization'] = localStorage.getItem('loginToken')
                if(subscribed || new Date() < new Date(expiration_date)){
                    store.dispatch(setSubscribed())
                }else{
                    store.dispatch(setUnsubscribed())
                }
                
            }).then( () => {
                store.dispatch(setSignedIn())
                redirectTo('/') //TODO https://github.com/glotools/trazal/issues/637
            })
            .catch(error => {
                store.dispatch(setAuthError(error))
                // throw error
            });
    },
    clearLoginData: function () {
      API.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('loginToken')
      localStorage.removeItem('loginTokenExp')
    },
    logOut: function(){
        store.dispatch(setAuthLoading());
        localStorage.removeItem('awsBucketName')
        localStorage.removeItem('awsSecretAccessKey')
        localStorage.removeItem('awsAccessKeyId')
        this.clearLoginData();
        localStorage.clear()

        API.delete(Urls.ApiLogOut)
          .then(function (response) {
            store.dispatch(setSignedOut())
          })
          .catch(function (error) {
            store.dispatch(setAuthError(error))
          })
          this.clearLoginData();
        redirectTo('/auth/sign-in')
    },
    changePassword: function(currentPassword, newPassword, passwordConfirmation, history) {
        if(newPassword != passwordConfirmation) {
            store.dispatch(enqueueSnackbar(warningMessage(snackbarMessages.warning.users.passwordMistmatch)))
            return
        }
        else {
            const data = { user:{ current_password: currentPassword, password: newPassword } }
            return API.put(Urls.ApiChangePassword, data)
                .then(function (response) {
                    store.dispatch(enqueueSnackbar(successMessage(snackbarMessages.success.users.changePassword)))
                    return response
                })
                .catch(function (error) {
                    store.dispatch(enqueueSnackbar(errorMessage(snackbarMessages.error.users.changePassword)))
                    throw error
                })
        }
    },
    resetPassword: function(email, history) {
        store.dispatch(setAuthLoading())
        const data = { user: { email: email } }
        return API.post(Urls.ApiResetPassword, data)
            .then(function (response) {
                store.dispatch(setResetPasswordDone())
                return response
            })
            .catch(function (error) {
                store.dispatch(setAuthError(error))
                throw error
            })
    },
    newPassword: function(token, newPassword, passwordConfirmation, history) {
        if(newPassword != passwordConfirmation) {
            var passwordMismatchError = {response: {data: {error: 'La contraseña y su confirmación no coinciden.'}}}
            store.dispatch(setAuthError(passwordMismatchError))
            return
        }
        else {
            store.dispatch(setAuthLoading())
            const data = { user: { password: newPassword, reset_password_token: token } }
            return API.put(Urls.ApiResetPassword, data)
                .then(function (response) {
                    store.dispatch(setNewPasswordDone())
                    redirectTo('/auth/sign-in')
                    return response
                })
                .catch(function (error) {
                    store.dispatch(setAuthError(error))
                    throw error
                })
        }
    },
    signUp: function(params) {
        API.post(Urls.ApiAutoSignUp, params).then(() => {redirectTo("/auth/sign-in?email=" + params.email)})
    }
}


import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  responseType: "json"
});


const loginToken = localStorage.getItem('loginToken');
if (loginToken) {
  API.defaults.headers.common['Authorization'] = loginToken;
}

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('loginToken');
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
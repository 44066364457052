import { combineReducers } from 'redux';

import auth from "./auth";
import themeReducer from './themeReducers';
import items from "./items";
import forms from "./forms"
import alertMessages from "./alertMessages";
import introJs from "./introJs"
import firstSteps from "./firstSteps";

export default combineReducers({
  alertMessages,
  auth,
  items,
  forms,
  themeReducer,
  introJs,
  firstSteps
});
